require('./bootstrap');
import ModalVideo from "modal-video";
var video_id = $('.js-modal-video').attr('data-video-id');
var video_id2 = $('.js-modal-video2').attr('data-video-id');
new ModalVideo('.js-modal-video',{
    youtube:{
        autoplay:1,
        mute:1,
        loop:1,
        rel:0,
        playlist:video_id,
        controls:1,
    }
});
new ModalVideo('.js-modal-video2',{
    youtube:{
        autoplay:1,
        mute:1,
        loop:1,
        rel:0,
        playlist:video_id2,
        controls:1,
    }
});

$(() => {
    // 「ページの中で一番上にあるエラー」に自動スクロール
    let top = 0;
    $('.is-invalid').each((index, element) => {
        let newTop = $(element).parent().offset().top;
        if(top === 0 || top > newTop) {
            top = newTop;
        }
        //console.log(top);
        $('html, body').animate({
            scrollTop: top
        }, 'fast');
    }); 
    // 開発実績のページのみ＃IDにスクロール
    if("/development"==location.pathname){
        var urlHash = window.location.hash;
        if(urlHash!=""){
            var pos = $(urlHash).offset().top - 100;  // 指定したID属性をもつ要素の上部の距離を取得
            $('body,html').animate({scrollTop: pos}, 'fast'); //取得した位置にスクロール
        }
    }
});
